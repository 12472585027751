
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "classFooter",
  props: {
    isBack: {
        type: Boolean,
        default: true
    },
    modelValue: {
      type: String,
      default: "",
    },
    playText: {
      type: String,
      default: "播放"
    }
  },
  setup(props, ctx) {

    const router = useRouter();
    function handleBack(){
        router.back();
    }

    function handlePlay(){
      ctx.emit("handle-play");
    }

    const isWriting = ref(false);
    const content = ref("");
    function showForm() {
      isWriting.value = true;
    }
    function hideForm() {
      isWriting.value = false;
    }
    function submit() {
      ctx.emit("submit");
      hideForm();
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLTextAreaElement).value);
    }

    return {
      handleBack,
      handlePlay,
      isWriting,
      submit,
      showForm,
      hideForm,
      handleInput
    };
  }
});
